import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'

/*

  HowTo:
    1. Add dynamic style to element and set style as `trHeight`
    2. Set transition speed using `transition: 0.35s height;` <= you can use appropriate value;
    3. Optionally you can set `overflow: hidden;` to hide element overflow while height is animated.
    4. Set initial height using `trSetHeight` before any operation. [mounted hook is recommended - You can use `ref` for dynamic contents]
    5. Toggle height using height operations 🍻
    6. Toggle usage of $nextTick for height operations is any issue occur [experimental] 🔬

*/
export const transitionApi = () => {
  const height = ref<number>(-1)
  const trHeight = computed(() => (height.value < 0 ? 'auto' : `${height.value}px`))

  const parseHeigth = (val: number | string) => {
    if (typeof val === 'number') {
      return val
    }

    if (val.match(/^\d{1,}$/)) {
      return Number(val)
    }

    return -1
  }

  // Add height to existing height
  // Usage: Where new element is append or more height is added (e.g. list append)

  /* Assumes:
   - Height is assigned and is `String`
   - Incoming value is valid number in `Number` or `String`
  */
  const trAddHeight = (val: number | string) => {
    height.value += parseHeigth(val)
  }

  // Remove height from existing height
  // Usage: Where new element is removed or height is remove (e.g. list pop/ele remove)

  /* Assumes:
   - Height is assigned and is `String`
   - Incoming value is valid number in `Number` or `String`
  */
  const trTrimHeight = (val: number | string) => {
    height.value -= parseHeigth(val)
  }

  // Set height
  // Usage: Mostly for assigning initial value from mounted hook

  /* Assumes:
   - Height is not assigned and what to assign for add/remove operation
   - What to set height at something for odd usage
   - Incoming value is valid number in `Number` or `String`
  */
  const trSetHeight = (val: number | string | null) => {
    if (val === null) {
      height.value = -1
    } else {
      height.value = parseHeigth(val)
    }
  }

  return {
    trHeight,
    trSetHeight,
    trAddHeight,
    trTrimHeight,
  }
}

// Ignore below for now. We will remove it when we add more transition in future.
export const _ = null
